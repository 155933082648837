/**
 * 共通ダイアログ・v-alertで使用するメッセージのキー
 */
export const clientMessageKeys = {
  // INFO
  I0001: 'I0001',
  I0002: 'I0002',
  I0003: 'I0003',
  // SUCCESS
  S0001: 'S0001',
  S0002: 'S0002',
  S0003: 'S0003',
  S0004: 'S0004',
  S0005: 'S0005',
  S0006: 'S0006',
  S0007: 'S0007',
  // WARNING
  W0001: 'W0001',
  W0002: 'W0002',
  W0003: 'W0003',
  W0004: 'W0004',
  W0005: 'W0005',
  W0006: 'W0006',
  W0007: 'W0007',
  W0008: 'W0008',
  W0009: 'W0009',
  W0010: 'W0010',
  W0011: 'W0011',
  W0012: 'W0012',
  W0013: 'W0013',
  W0014: 'W0014',
  W0015: 'W0015',
  W0017: 'W0017',
  W0018: 'W0018',
  W0019: 'W0019',
  W0020: 'W0020',
  W0021: 'W0021',
  // ERROR
  E0001: 'E0001',
  E0002: 'E0002',
  E0003: 'E0003',
  E0004: 'E0004',
  E0005: 'E0005',
  E0006: 'E0006',
  E0007: 'E0007',
  E0008: 'E0008',
  E0009: 'E0009',
  E0010: 'E0010',
  E0011: 'E0011',
  E0012: 'E0012',
  E0013: 'E0013',
  E0014: 'E0014',
  E0015: 'E0015',
  E0016: 'E0016',
  E0017: 'E0017',
  E0018: 'E0018',
  E0019: 'E0019',
  E0020: 'E0020',
  E0021: 'E0021',
  E0022: 'E0022',
  E0023: 'W0023',
};

/**
 * 共通ダイアログ・v-alertで表示するメッセージ
 */
export const clientMessages = {
  // INFO
  I0001: '保存します<br>よろしいですか',
  I0002: '検索結果が1000件を超えました。先頭の1000件を表示します。',
  I0003: `登録件数が上限に達しています`,
  // SUCCESS
  S0001: '登録しました',
  S0002: '削除しました',
  S0003: '保存しました',
  S0004: '更新しました',
  S0005: 'ダウンロードしました',
  S0006: 'アップロードしました。<br>しばらくすると組合員へ自動的にメール通知されます。',
  S0007: 'アップロードしました。<br>しばらくすると営業担当者へ自動的にメール通知されます。',
  // WARNING
  W0001: '削除します<br>よろしいですか<br><br>{items}',
  W0002: 'データが変更されています<br>保存せずページを離れてよろしいですか',
  W0003: 'ユーザーの更新成功しました。相手先のお知らせメールが発送失敗しました。手動でメールを発送してください',
  W0004: '削除します<br>よろしいですか<br>{userName}',
  W0005: 'ユーザーは自分を削除できません',
  W0006: '同じログインIDが登録済みのため、他のログインIDを試してください',
  W0007: '同じメールアドレスが登録済みのため、他のメールアドレスを試してください',
  W0008: '同じ営業番号が登録された可能性あります、他の営業番号を試してください',
  W0009: '認証コードを送信しました。認証コードと新しいパスワードを入力してください',
  W0010: '初期パスワードを変更する必要があります。新しいパスワードを入力してください<br>パスワードは数字、アルファベットの小文字を含んだ、8文字以上の文字列を設定してください',
  W0011: '同じパスワードを入力してください',
  W0012: '新しいパスワードは変更前のパスワードと異なるパスワードである必要があります',
  W0013: '同じカードIDすでに登録されています',
  W0014: '組合員番号かカードIDがすでに登録されました',
  W0015: 'カードIDがすでに登録されました',
  W0017: '一回アップロードできるzipファイルは一つまでです。',
  W0018: '一回のアップロードできるファイル数は{fileCountLimit}件まで。',
  W0019: '一回のアップロードできるファイルサイズは500MB',
  W0020: '認証コードが正しくありません',
  W0021: '{mouth}月分の情報を全部削除します。<br><br>よろしいですか。<br><br>',
  // ERROR
  E0001: '予期せぬエラーが発生しました。',
  E0002: 'データの登録でエラーが発生しました',
  E0003: 'データの更新でエラーが発生しました',
  E0004: 'データの削除でエラーが発生しました',
  E0005: '予期せぬエラーが発生しました。ユーザー削除されました可能性あります。',
  E0006: '予期せぬエラーが発生しました。データ削除されました可能性あります。',
  E0007: '存在しないログインIDです',
  E0008: 'パスワードは数字、アルファベットの小文字を含んだ、8文字以上の文字列を設定してください',
  E0009: '所定の回数を超えるリクエストが送信されました。しばらく時間をおいてから再度お試しください',
  E0010: 'ユーザーテーブルにデータがありません',
  E0011:
    'ログインIDかパスワードが正しくありません。<br>パスワードは数字、アルファベットの小文字を含んだ8文字以上の文字列です。',
  E0012: '認証エラーが発生しました',
  E0013:
    'パスワードは数字、アルファベットの小文字を含んだ8文字以上の文字列を設定してください。',
  E0014: '「新パスワード」と「パスワード（確認）」の値が異なります',
  E0015: 'データのダウンロードでエラーが発生しました',
  E0016: 'データの検索でエラーが発生しました',
  E0017: 'アップロードが失敗しました。<br>ファイル名が規則と合ってません<br>{fileList}',
  E0018: 'アップロードが失敗しました。<br>ファイルタイプが規則と合ってません<br>{fileList}',
  E0019: 'アップロードが失敗しました。<br>{fileList}',
  E0020:
    'ZIPファイルのアップロードが失敗しました。<br>ZIPの中身、以下のファイル名がファイル命名規則と合ってません<br>{fileList}',
  E0021: '画像ファイルのアップロードでエラーが発生しました',
  E0022: 'データの取得時にエラーが発生しました',
  E0023: '認証コードの有効期限が切れています。再度パスワードのリセットを行ってください',
};

/**
 * メッセージキーからメッセージ情報を取得
 * @param {*} messageKey
 * @returns
 */
export const getClientMessage = (messageKey) => {
  const alertType = getAlertType(messageKey);
  console.log({ alertType });
  console.log('clientMessages[messageKey]', clientMessages[messageKey]);
  const message = clientMessages[messageKey];
  console.log({ message });
  return {
    alertType: alertType,
    message: message,
  };
};

/**
 * メッセージキーからメッセージタイプを取得
 * @param {*} messageKey
 * @returns
 */
const getAlertType = (messageKey) => {
  return ALERT_TYPE[messageKey.substring(0, 1)];
};

/**
 * アラートタイプ
 */
export const ALERT_TYPE = {
  I: 'info',
  S: 'success',
  W: 'warning',
  E: 'error',
};
